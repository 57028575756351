import { useState, useEffect } from "react";

const host = window.location.host === "localhost:3001" ? "localhost:3000" : window.location.host;

const socket = new WebSocket(`${window.location.protocol === "https:" ? "wss:" : "ws:"}//${host}/`);

export const useWebSocket = () => {
  const [event, setEvent] = useState(undefined);

  useEffect(() => {
    function handleMessage(event) {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch(e) {
        return;
      }

      setEvent(data);
    }

    socket.addEventListener('message', handleMessage);

    return () => {
      socket.removeEventListener('message', handleMessage);
    };
  }, [setEvent]);

  return {
    event: event,
    socket: socket
  };
};
