import React, { useState, useCallback, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconBellOff from "@material-ui/icons/NotificationsNone";
import IconBellOn from "@material-ui/icons/Notifications";
import IconBellDenied from "@material-ui/icons/NotificationsOff";

function getNotificationPermissionState() {
  if (!("Notification" in window)) {
    return null;
  }

  if (Notification.permission === "granted") {
    return true;
  }

  if (Notification.permission === "denied") {
    return false;
  }

  return undefined;
}

function askPermission() {
  return new Promise(function(resolve, reject) {
    const permissionResult = Notification.requestPermission(function(result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
}

const PushBell = () => {
  const [permission, setPermission] = useState(null);

  useEffect(() => {
    setPermission(getNotificationPermissionState());
  }, [setPermission]);

  const [enabled, setEnabled] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const [deniedDialogOpen, setDeniedDialogOpen] = useState(false);

  const handleCloseDialog = useCallback(() => {
    setDeniedDialogOpen(false);
  }, [setDeniedDialogOpen]);

  const handleBellClick = useCallback((event) => {
      if (permission === false) {
          setDeniedDialogOpen(true);
          return;
      }

      setMenuAnchor(event.currentTarget);
  }, [permission, setMenuAnchor, setDeniedDialogOpen]);

  const handleEnableClick = useCallback(() => {
    askPermission()
      .then(() => {
        const perm = getNotificationPermissionState();
        setPermission(perm);

        if (perm === true) {
          setEnabled(true);
        }
      });
    setMenuAnchor(null);
  }, [setEnabled, setPermission, setMenuAnchor])

  const handleDisableClick = useCallback(() => {
    setEnabled(false);
    setMenuAnchor(null);
  }, [setEnabled, setMenuAnchor])

  const handleClose = useCallback(() => {
    setMenuAnchor(null);
  }, [setMenuAnchor]);

  if (permission === null) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton color={enabled ? "primary" : "secondary"} onClick={handleBellClick}>
        {permission === false && (<IconBellDenied />)}
        {enabled && permission && (<IconBellOn />)}
        {!enabled && permission !== false && (<IconBellOff />)}
      </IconButton>
      <Menu anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleClose}
      >
        {Boolean(menuAnchor) && !enabled && (<MenuItem onClick={handleEnableClick}>Turn On Notifications?</MenuItem>)}
        {Boolean(menuAnchor) && enabled && (<MenuItem onClick={handleDisableClick}>Turn Off Notifications?</MenuItem>)}
      </Menu>
      <Dialog open={deniedDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">Push Notifications Denied</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Notification permission has been denied, to allow notifications,
            you must update the permissions for this app in your system settings.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PushBell;
