import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Theme from "./Theme.js";
import Status from "./Status.js";
import './App.css';

function App() {
  return (
    <Theme>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Status} />
        </Switch>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
