import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Fab from '@material-ui/core/Fab';
import Slider from '@material-ui/core/Slider';
import IsJeffIcon from "@material-ui/icons/Eject.js"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import PushBell from "./push/PushBell.js";
import { useWebSocket } from "./useWebSocket.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%"
  },
  media: {
    height: 140,
    backgroundPositionY: "-200px"
  },
  currentworry: {
    margin: theme.spacing(3)
  },
  setworrypanel: {
    height: "400px",
    margin: theme.spacing(1)
  },
  wordsofcomfort: {
    margin: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: 10,
    right: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  push: {
    position: "fixed",
    bottom: theme.spacing(1),
    left: theme.spacing(1)
  }
}));

const levels = [
  "Nope not really that worried",
  "No worries",
  "Super mildly worried",
  "Barely worried",
  "Slightly more than zero worried",
  "Might have some worries",
  "Worries to be had",
  "Gettin' my worry on",
  "Solidly worried.",
  "Full-on sendit worry",
  "Extreme worry, be worried."
];

function valuetext(value) {
  return levels[value];
}

const marks = levels.map((level, index) => ({
    value: index,
    label: level
}));

const Status = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [level, setLevel] = useState(0);
  const [wordsOfComfort, setWordsOfComfort] = useState("");
  const [isJeff, setIsJeff] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDefinitelyJeff, setIsDefinitelyJeff] = useState(undefined);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { event: socketEvent } = useWebSocket();

  useEffect(() => {
    if (socketEvent && socketEvent.type === "notification" && socketEvent.topic === "worry.updated") {
      setLevel(socketEvent.data.level);
      setWordsOfComfort(socketEvent.data.comfort);
    }
  }, [socketEvent, setLevel, setWordsOfComfort]);

  useEffect(() => {
    async function loadWorry() {
      const res = await fetch("/worry.json");
      const data = await res.json();

      setLevel(data.level);
      setWordsOfComfort(data.comfort);
      setIsLoading(false);
    }
    loadWorry();
  }, [setLevel, setWordsOfComfort, setIsLoading]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartUpdate = useCallback(() => {
    setIsUpdating(true);
  }, [setIsUpdating]);

  const handleDefinitelyJeff = useCallback(() => {
    setIsJeff(true);
    setIsDefinitelyJeff(true);
  }, [setIsJeff, setIsDefinitelyJeff]);

  const handleNotJeff = useCallback(() => {
    setIsJeff(false);
    setIsDefinitelyJeff(false);
  }, [setIsJeff, setIsDefinitelyJeff]);

  const handleSubmitUpdate = useCallback(async () => {
    await fetch("/worry.json", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        level: level,
        comfort: wordsOfComfort
      })
    });

    setIsUpdating(false);
  }, [level, wordsOfComfort, setIsUpdating]);

  const handleLevelChange = useCallback((event, newLevel) => {
    setLevel(newLevel);
  }, [setLevel]);

  const handleWordsChange = useCallback((event) => {
    setWordsOfComfort(event.target.value || "");
  }, [setWordsOfComfort]);

  if (isLoading) {
    return (
      <div className={classes.root}>
        <Typography variant="h3">
          Calibrating worry
        </Typography>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>

      {!isUpdating && (
        <Card className={classes.currentworry}>
          <CardHeader
            avatar={
              <Avatar aria-label="jeff" src="worries.jpg">
                Jeff
              </Avatar>
            }
            title="Is Jeff Even Worried?"
            subheader={(new Date()).toLocaleString()}
          />
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Current Status
            </Typography>
            <Typography variant="h5" component="h2">
              {levels[level]}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              Level {level} worry
            </Typography>
            <Typography variant="body2" component="p">
              {wordsOfComfort}
            </Typography>
          </CardContent>
          {isJeff && (
            <CardActions>
              <Button size="small" color="primary" onClick={handleStartUpdate}>Update</Button>
            </CardActions>
          )}
        </Card>
      )}

      {isJeff && isUpdating && (
        <div className={classes.setworrypanel}>
          <Typography id="discrete-slider" gutterBottom>
            Worry Level
          </Typography>
          <Slider
            defaultValue={0}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            orientation="vertical"
            step={1}
            marks={marks}
            min={0}
            max={10}
            onChange={handleLevelChange}
            value={level}
          />
          <form className={classes.wordsofcomfort} noValidate autoComplete="off">
            <TextField id="words-of-comfort" label="Words of Comfort" variant="outlined" value={wordsOfComfort} onChange={handleWordsChange} />
            <Button variant="contained" color="primary" onClick={handleSubmitUpdate}>Update Worry</Button>
          </form>
        </div>
      )}

      {!isJeff && isDefinitelyJeff === undefined && (
        <React.Fragment>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            className={classes.margin}
            aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
          >
            <IsJeffIcon className={classes.extendedIcon} />
            Are you Jeff?
          </Fab>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDefinitelyJeff}>Yes, I am Jeff</MenuItem>
            <MenuItem onClick={handleNotJeff}>No I'm worried.</MenuItem>
          </Menu>
        </React.Fragment>
      )}

      {!isUpdating && (
        <div className={classes.push}>
          <PushBell />
        </div>
      )}
    </div>
  );
};

export default Status;
