import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

const Theme = ({ children }) => {
  const theme = createMuiTheme();

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default Theme;
